import {
  always,
  compose,
  equals,
  filter,
  ifElse,
  isEmpty,
  isNil,
  not,
  path,
  propOr,
  anyPass,
  curryN,
  prop,
  replace
} from 'ramda'

import format from 'format-number'
import moment from 'moment'

const formatNumber = (num) => format({})(num)

const isTrue = equals(true)

const isNotTrue = compose(not, isTrue)

const isNotNil = compose(not, isNil)

const isNotEmpty = compose(not, isEmpty)

const isNilOrEmpty = anyPass([isNil, isEmpty])

const propIsNil = propName => compose(
  isNil,
  prop(propName)
)

const isEmail = (value) => {
  const pattern = new RegExp(
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
  )

  return pattern.test(value)
}

const isServerResponseError = compose(not, equals(200), propOr(200, 'status'))

const hasError = compose(isNotNil, path(['data', 'error']))

const filterByPropEquals = (defaultValue, comparisonValue, propName) =>
  filter(compose(equals(comparisonValue), propOr(defaultValue, propName)))

const toString = ifElse(isNil, always(''), (_) => _ + '')

const keyLookUp = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

const loadJquery = () =>
  new Promise((resolve, reject) =>
    import('jquery')
      .then(({ default: jQuery }) => {
        window.jQuery = window.$ = jQuery
        resolve(jQuery)
      })
      .catch(reject),
  )

const convertDate = (date) => {
  const dd = date.getDate() + ''
  const mm = date.getMonth() + 1 + ''
  const yyyy = date.getFullYear() + ''
  return `${yyyy}-${mm.padStart(2, '0')}-${dd.padStart(2, '0')}`
}

const convertDateToYM = (date) => {
  const mm = date.getMonth() + 1 + ''
  const yyyy = date.getFullYear() + ''
  return `${mm.padStart(2, '0')}/${yyyy}`
}

const convertDateWithShotMonthName = (date) => {
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  return `${da} ${mo} ${ye}`
}

const convertDateMYWithShotMonthName = (date) => {
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  return `${mo} ${ye}`
}

const getDateTimeStringForNameFileImport = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  const hour = String(today.getHours()).padStart(2, '0');
  const minute = String(today.getMinutes()).padStart(2, '0');
  const second = String(today.getSeconds()).padStart(2, '0');

  const date = yyyy + mm + dd +'_'+ hour + minute + second;
  return date;
}

const formatDateForNotifications = (date) => {
  const dataDate = new Date(date);
  const dd = String(dataDate.getDate()).padStart(2, '0');
  const mm = String(dataDate.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = dataDate.getFullYear();
  const hour = String(dataDate.getHours()).padStart(2, '0');
  const minute = String(dataDate.getMinutes()).padStart(2, '0');

  // return `${hour}:${minute} ${dd}/${mm}/${yyyy}`;
  return `${hour}:${minute}`;
}

const formatDateDDMMMYYYY = (date) => {
  // trim
  const data = typeof date === 'string'
    ? replace(/\"/g, '', date)
    : date
  return moment(data).isValid()
    ? moment(data).format('DD MMM YYYY')
    : ''
}

const firstDateOfMonthsAgo = (month) => {
  const date = new Date(new Date().setDate(1))
  return new Date(date.setMonth(date.getMonth() - month))
}

const lastDateOfMonthsAgo = (month) => {
  const date = firstDateOfMonthsAgo(month)
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

const convertDateForAlert = (date) => {
  const dateObj = new Date(date)
  return (
    dateObj.toDateString() +
    ' ' +
    dateObj.toLocaleTimeString('en-US', { hour12: false, timeStyle: 'short' })
  )
}

const jsonStringToValue = val => {
  let cleanedVal = val
  while(/[\\"']/.test(cleanedVal)){
    cleanedVal = cleanedVal.replace(/[\\"']/g, '');
  }
  return cleanedVal
}

const addDays = curryN(2, (date, days) => {
  const newDate = new Date(date.valueOf())
  newDate.setDate(newDate.getDate() + days)
  return newDate
})

const addMonths = curryN(2, (date, months) => {
  const newDate = new Date(date.valueOf())
  newDate.setMonth(newDate.getMonth() + months)
  return newDate
})

const randomColor = (index) => {
  const colorNames1 = {
    aqua: '#00ffff',
    darkkhaki: '#bdb76b',
    darkmagenta: '#8b008b',
    darkolivegreen: '#556b2f',
  }

  const colorNames2 = {
    darksalmon: '#e9967a',
    fuchsia: '#ff00ff',
    gold: '#ffd700',
  }

  const colorNames3 = {
    maroon: '#800000',
    navy: '#000080',
    purple: '#800080',
    red: '#ff0000',
  }

  const colorNames  = {
    lime: '#00ff00',
    darkcyan: '#008b8b',
    darkgrey: '#a9a9a9',
    indigo: '#4b0082',
    darkgreen: '#006400',

  }
  let result = null
  let count = 0

  if (index == 1) {
    for (var colorName in colorNames1) {
      if (Math.random() < 0.2) break
      if (Math.random() < 1 / ++count) result = colorName
    }
  } else if(index == 2) {
    for (var colorName in colorNames2) {
      if (Math.random() < 0.2) break
      if (Math.random() < 1 / ++count) result = colorName
    }
  } else if(index == 3) {
    for (var colorName in colorNames3) {
      if (Math.random() < 0.2) break
      if (Math.random() < 1 / ++count) result = colorName
    }
  } else if (index == 4) {
    for (var colorName in colorNames) {
      if (Math.random() < 0.2) break
      if (Math.random() < 1 / ++count) result = colorName
    }
  } else {
    result = '#' + Math.floor(Math.random() * 16777215).toString(16);
  }
  result = result
    ? result
    : '#' + Math.floor(Math.random() * 16777215).toString(16)

  if (result === '#000000' || result === '#ffffff')
    return randomColor()
  else
    return result
}

const toNumber = ifElse(isNil, always(''), (_) => _ - '')

const randomIntBetween = (min, max) => {
  const minInt = Math.ceil(min)
  const maxInt = Math.floor(max)
  return Math.floor(
      Math.random() * (maxInt - minInt) + minInt
  )
}

const roundNumber = (number) => {
  if (number < 0) {
    return 0;
  }
  return Math.round(number * 1000) / 1000;
}

const getDisplayName = (customer) => {
  const owner_name = propOr("", "owner_name")(customer);
  const trading_name = propOr("", "trading_name")(customer);
  const site_name = propOr("", "site_name")(customer);

  let display_name = !!owner_name ? owner_name + "" : "";
  if (display_name !== trading_name && !!trading_name) {
    display_name += !!display_name ? ` trading as ${trading_name}` : trading_name;
  }
  display_name += !!site_name ? ` - ${site_name}` : "";

  return display_name.replace(/^\s+|\s+$/, "");
};

export {
  jsonStringToValue,
  propIsNil,
  formatNumber,
  isTrue,
  isNotTrue,
  isNotNil,
  isNotEmpty,
  isNilOrEmpty,
  isEmail,
  isServerResponseError,
  hasError,
  filterByPropEquals,
  toString,
  keyLookUp,
  loadJquery,
  convertDate,
  convertDateToYM,
  firstDateOfMonthsAgo,
  lastDateOfMonthsAgo,
  convertDateForAlert,
  addDays,
  addMonths,
  randomColor,
  toNumber,
  randomIntBetween,
  convertDateWithShotMonthName,
  convertDateMYWithShotMonthName,
  roundNumber,
  getDateTimeStringForNameFileImport,
  getDisplayName,
  formatDateForNotifications,
  formatDateDDMMMYYYY
}

